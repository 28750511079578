<template>
  <div>
    <b-container class="m-0 w-100" fluid>
      <header-slot> </header-slot>
      <b-nav card-header pills class="m-0">
        <b-nav-item
        v-for="item in items" :key="item.id"
        :to=" { name : item.routeName } "
        :active="$route.matched[2].name == item.routeName"
        exact-active-class="active" 
        :link-classes="['px-3',bgTabsNavs]"
        >
          {{ item.descripcion.toUpperCase() }}
        </b-nav-item>
      </b-nav>
      <template>
        <b-card class="border-top-primary border-3 border-table-radius px-0">
          <router-view :key="$route.name" />
        </b-card>
      </template>
    </b-container>
  </div>
</template>

<script>
import data from "../data/db1.json";
export default {
  data() {
    return {
      items: data,
      tab: 1,
    };
  },
};
</script>

<style scoped>
</style>